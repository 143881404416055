import React, { useRef } from 'react';
import { Nav } from '../content/nav/Nav';
import { PageHolder } from '../style/PageHolder';
import styled from 'styled-components';
import { useScrollInfo } from '@dontsave/scroll-effects';

export type Props = {};

export const NavWithPage:React.FC<Props> = props => {
	const ref = useRef<HTMLDivElement>();

	const info = useScrollInfo({
		elementRef: ref,
	});

	let scrollRatio = 1;
	if (info) {
		scrollRatio = info.elementRect.top < -70 ? -1 : 0;
	}

	return (
		<>
			<Nav scrollRatio={scrollRatio} />
			<PageHolder ref={ref}>{props.children}</PageHolder>
		</>
	);
};
