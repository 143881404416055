import React, { createContext } from 'react';
import { GatsbyPageProps } from '../gatsby/types/GatsbyPageProps';

export type Props = {
	pageProps:GatsbyPageProps;
};

export const SiteContext = createContext<Props>({
	pageProps: null,
});
