import { css } from 'styled-components';

export const couch = (couchedWidth:number, minPadding:number) => {
	const paddingCalc = `calc((100% - ${couchedWidth}px) / 2)`;
	const maxW = couchedWidth + minPadding;
	return css`
		box-sizing: border-box;

		padding-right: ${paddingCalc};
		padding-left: ${paddingCalc};

		@media only screen and (max-width: ${maxW}px) {
			padding-right: calc(${minPadding}px / 2);
			padding-left: calc(${minPadding}px / 2);
		}
	`;
};
