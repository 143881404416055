// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-attorneys-tsx": () => import("/opt/build/repo/src/pages/attorneys.tsx" /* webpackChunkName: "component---src-pages-attorneys-tsx" */),
  "component---src-pages-careers-tsx": () => import("/opt/build/repo/src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("/opt/build/repo/src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("/opt/build/repo/src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

