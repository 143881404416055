import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { GlobalStyle } from '../style/GlobalStyle';
import { PageHolder } from '../style/PageHolder';
import { GatsbyPageProps } from '../gatsby/types/GatsbyPageProps';
import { Nav } from '../content/nav/Nav';
import { SiteContext } from './SiteContext';
import { NavWithPage } from './NavWithPage';
import styled from 'styled-components';
import { Link } from 'gatsby';

export type Props = GatsbyPageProps;

const Footer = styled.div`
	padding: 20px;
	text-align: center;
`;

export const Layout: React.FC<Props> = props => {
	return (
		<GlobalStyle>
			<SiteContext.Provider value={{ pageProps: props }}>
				<Helmet>
					<title>Sloss Eckhouse Dasti Haynes LawCo.</title>
					<link
						href='https://fonts.googleapis.com/css?family=Barlow:400,700'
						rel='stylesheet'
					/>
					<meta charSet='utf-8' />
					<meta httpEquiv='X-UA-Compatible' content='IE=edge' />
					<meta name='viewport' content='width=device-width, initial-scale=1' />
				</Helmet>
				<NavWithPage>{props.children}</NavWithPage>
				<Footer>
					<Link to={'/disclaimer'}>Disclaimer</Link>
				</Footer>
			</SiteContext.Provider>
		</GlobalStyle>
	);
};
