import React, { useContext, forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Logo } from '../vector/Logo';
import { Color } from '../../style/Color';
import { NavItem } from './NavItem';
import GatsbyLink from 'gatsby-link';
import { mainCouch } from '../../style/mainCouch';
import { SiteContext } from '../../layout/SiteContext';
import { Hamburger } from '../vector/Hamburger';

export type Props = {
	scrollRatio?: number;
};

const NavHolder = styled.div`
	${mainCouch};

	font-size: 19px;
	position: fixed;
	z-index: 999999;
	width: 100%;
	top: 0px;
	left: 0px;
	transition: all 0.25s;
	height: 115px;

	display: flex;
	justify-content: space-between;
	background-color: ${Color.BG};

	padding-top: 30px;
	padding-bottom: 30px;
	@media (max-width: 768px) {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	padding-top: 10px;
	padding-bottom: 10px;

	.hamburger {
		fill: ${Color.HILIGHT};
		position: relative;
		top: 28px;
		@media (min-width: 769px) {
			display: none;
		}

		cursor: pointer;
	}

	.logo {
		padding: 20px;

		height: 75px;
		width: 60px;

		margin-right: 20px;
		transition: all 0.25s;
		border: 1px solid ${Color.HILIGHT};
		padding: 9px;
	}

	.hamholder {
		display: none;
		@media (max-width: 768px) {
			display: block;
		}
	}
`;

const NavItems = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width: 768px) {
		display: none;
	}
`;

const MobileNavItems = styled.div`
	width: 100%;
	position: fixed;
	top: 110px;
	left: 0px;
	z-index: 9999999999999999;
	min-height: calc(100vh - 95px);
	background-color: ${Color.BG};
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;
	&.visible {
		opacity: 1;
		visibility: visible;
	}
`;

const MobileNavItem = styled(GatsbyLink)<{ selected: boolean }>`
	display: block;
	${mainCouch}
	width: 100%;
	text-decoration: none;

	&:first-child {
		margin-top: 40px;
	}
	padding-top: 20px;
	padding-bottom: 12px;
	font-size: 40px;
	color: ${Color.NAV_TEXT};
	text-transform: uppercase;
`;

export const sections = [
	'about',
	'attorneys',
	'projects',
	'careers',
	'contact',
];

export const Nav = forwardRef<Element, Props>((props, ref) => {
	const { pageProps } = useContext(SiteContext);
	let current = pageProps['*'];
	if (current === '') current = 'about';

	const [mobileVis, setMobileVis] = useState(false);
	return (
		<>
			<NavHolder className={props.scrollRatio < 0 ? 'scrolled' : ''}>
				<GatsbyLink to='/'>
					<Logo className={props.scrollRatio < 0 ? 'scrolled' : ''} />
				</GatsbyLink>
				<NavItems>
					{sections.map(s => (
						<NavItem
							selected={current === s}
							key={s}
							to={`/${s === 'about' ? '/' : s}`}
						>
							{s}
						</NavItem>
					))}
				</NavItems>
				<div className={`hamholder`} onClick={() => setMobileVis(!mobileVis)}>
					<Hamburger />
				</div>
			</NavHolder>
			<MobileNavItems className={mobileVis ? `visible` : ``}>
				{sections.map(s => (
					<MobileNavItem
						selected={current === s}
						onClick={() => setMobileVis(false)}
						key={s}
						to={`/${s === 'about' ? '/' : s}`}
					>
						{s}
					</MobileNavItem>
				))}
			</MobileNavItems>
		</>
	);
});
