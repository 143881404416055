import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { resetCSS } from './reset';
import { Color } from './Color';

const GatsbyGlobal = createGlobalStyle`
	${resetCSS}
	
	body {
		font-family: 'Barlow', sans-serif;
		background-color:${Color.WHITE};
		color:${Color.TEXT};
	}
	
`;

export type Props = {};

export const GlobalStyle:React.FC<Props> = p => {
	return (
		<>
			<GatsbyGlobal />
			{p.children}
		</>
	);
};
