import React from 'react';
import GatsbyLink from 'gatsby-link';
import styled from 'styled-components';
import { Color } from '../../style/Color';

export type Props = {
	to:string;
	children:string;
	selected?:boolean;
};

const Item = styled.div`
	&:not(:last-child) {
		margin-right: 50px;
	}

	position: relative;
	a {
		text-transform: uppercase;

		text-decoration: none;
		color: ${Color.NAV_TEXT};

		&::after {
			content: '';
			position: absolute;
			bottom: -5px;
			left: 0px;
			width: 100%;
			height: 1px;
			transform: scaleX(0);
			transform-origin: center;
			transition: transform 0.3s, opacity 0.8s;
			opacity: 0;
			background-color: ${Color.HILIGHT};
		}
	}

	a.selected {
		color: ${Color.HILIGHT};
		&::after {
			transform: scaleX(1);
			opacity: 1;
		}
	}
`;

export const NavItem:React.FC<Props> = props => {
	return (
		<Item>
			<GatsbyLink className={props.selected ? 'selected' : ''} to={props.to}>
				{props.children}
			</GatsbyLink>
		</Item>
	);
};
