import styled from 'styled-components';
import { mainCouch } from './mainCouch';
import { Color } from './Color';
import { css } from 'styled-components';

export const CouchedDiv = styled.div`
	${mainCouch};
`;

export const pagePadding = css`
	margin-top: 100px;
`;

export const PageHolder = styled.div`
	${pagePadding};

	font-size: 17px;

	a {
		color: ${Color.HILIGHT};
		text-decoration: none;
		&:not(.inline) {
			font-weight: bold;
			&::before {
				content: '⯈';
				padding-right: 15px;
			}
		}
	}

	section {
		margin-bottom: 80px;
	}

	p,
	h1 {
		line-height: 32px;
		margin-bottom: 30px;

		.sub {
			display: block;
			color: black;
			font-weight: normal;
			font-size: 0.8em;
			line-height: 0.9em;
			text-transform: uppercase;
		}

		&.big {
			font-size: 1.4em;

			.sub {
				font-size: 0.6em;
			}
		}
	}

	p {
		line-height: 24px;
	}

	strong {
		font-weight: bold;
	}

	h1 {
		color: ${Color.HILIGHT};
		font-size: 1.7em;
		font-weight: 700;
	}
`;
